<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;" class="empowerbox">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">授权公司名称：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="empowerdata.empowername"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">授权地区：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="empowerdata.empoweraddress"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">授权日期：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="empowerdata.empowertime"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">公司地址：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="empowerdata.address"></el-input>
			</div>
			
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">授权公司联系方式：</label>
				<el-input placeholder="请输入内容" maxlength="11" style="width:90%;" v-model="empowerdata.phone"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">授权公司的logo：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" 
					:on-success="upload">
					<img v-if="empowerdata.logo" :src="empowerdata.logo" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">授权书图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" 
					:on-success="upload1">
					<img v-if="empowerdata.empowerimgurl" :src="empowerdata.empowerimgurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="addempower">新增授权</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				empowerdata: {
					empowertime:'',// 授权日期
					address:'',//公司地址
					logo:'',//授权公司logo
					empoweraddress:'',//授权地区
					empowerimgurl:'',//授权书图片
					phone:'',//授权公司联系方式
					empowername:'',//授权公司名称
					updatatime:''//编辑时间
				},
				editor: null,
				toolbarConfig: {},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			addempower() {
				// 新增授权
				
				if (this.empowerdata.empowername == "" || this.empowerdata.empoweraddress == ''){
					this.$message({
						showClose: true,
						message: '请填写授权公司名称或授权地区',
						type: 'error'
					});
				} else if (this.empowerdata.empowerimgurl == '') {
					// 未上传图片
					this.$message({
						showClose: true,
						message: '请上传授权书图片',
						type: 'error'
					});
				} else if(this.empowerdata.empowertime == ""){
					// 未输入授权日期
					this.$message({
						showClose: true,
						message: '请输入授权日期',
						type: 'error'
					});
				}else if(this.empowerdata.logo == ""){
					// 未上传公司logo
					this.$message({
						showClose: true,
						message: '请上传授权公司logo',
						type: 'error'
					});
				}else{
					let that = this
					this.$confirm('确定要新增吗', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then((res1) => {
						console.log(res1);
						if(res1 == 'confirm'){
							console.log('确认');
							that.$post("/empower/addempower",that.empowerdata).then(res=>{
								if(res.data=='发布成功'){
									this.$message({
										type: 'success',
										message: '新增成功!'
									});
								}else{
									this.$message({
										type: 'error',
										message: '新增失败!'
									});
								}
							})
						}else{
							console.log('取消');
							this.$message({
								type: 'info',
								message: '已取消'
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			upload(e) {
				console.log(e);
				if (e.data.url) {
					this.empowerdata.logo = e.data.url
				}
			},
			upload1(e) {
				console.log(e);
				if (e.data.url) {
					this.empowerdata.empowerimgurl = e.data.url
				}
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			this.empowerdata.updatatime = this.$gettime()
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	
	.empowerbox div{
		margin: 15px 0;
		line-height: 30px;
	}
</style>